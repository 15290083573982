import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { MdFileUpload, MdDone } from "react-icons/md"
import BlockContent from "@sanity/block-content-to-react"
import serializers from "../components/serializers"
export default () => (
  <StaticQuery
    query={graphql`
      query contactQuery {
        sanityApplication {
          title
          _rawSubtitle
        }
      }
    `}
    render={data => (
      <div id="application-form" className="applicationForm">
        <p className="title ">{data.sanityApplication.title}</p>
        <BlockContent
          blocks={data.sanityApplication._rawSubtitle}
          projectId="ppd47c0u"
          dataset="production"
          serializers={serializers}
        />{" "}
        {/* <p className="subtitle ">{data.sanityApplication.subtitle}</p> */}
        <form
          className="application_form"
          name="application-form"
          method="POST"
          action="/mail-sendt"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          enctype="multipart/form-data"
        >
          {/* <input type="hidden" name="bot-field" /> */}
          <input type="hidden" name="form-name" value="application-form" />

          <div className="upper_wrapper">
            <div className="input_wrapper">
              <input type="text" name="navn" id="navn" required />
              <label htmlFor="navn">Navn</label>
            </div>
            <div className="input_wrapper">
              <input type="text" name="email" id="email" required />
              <label htmlFor="email">Email</label>
            </div>
            <div className="input_wrapper">
              <input
                type="file"
                name="cv"
                id="cv"
                required
                className="hiddenInput"
                onInput={fileUploaded}
              />
              <label htmlFor="cv" className="visibleLabel">
                Upload dit CV <MdFileUpload className="fileNotUploaded" />
                <MdDone className="fileIsUploaded" />
              </label>
            </div>
          </div>

          <div className="input_wrapper message ">
            <textarea name="message" id="message" rows="6" required />
            <label htmlFor="message">Besked</label>
          </div>
          <div>
            <input
              className="sendButton "
              type="submit"
              value="Send"
              // onClick={defineAction}
            />
          </div>
        </form>
      </div>
    )}
  />
)

// function defineAction() {
//   let getURL = window.location.href

//   let shortenURL = getURL.replace("https://zignifikant.dk", "")

//   document.querySelector(".contact_form").action = shortenURL + "/mail-sendt"
// }

function fileUploaded(e) {
  if (e.target.files.length != 0) {
    e.target.parentNode.querySelector(".fileNotUploaded").style.display = "none"
    e.target.parentNode.querySelector(".fileIsUploaded").style.display = "block"
  }
}
